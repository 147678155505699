<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ event.id ? 'Editar': 'Agregar' }} Hora
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <!-- <b-alert
            v-if="optionsAsignaturas.length === 0"
            variant="primary"
            show
            class="text-center mt-25 mr-25 ml-25 pb-2 pt-1"
          >
          </b-alert> -->
          <b-form
            class="p-2"
            @submit.prevent="submitOption()
              ? handleSubmit(onSubmit)
              : ''"
            @reset.prevent="resetForm"
          >
            <b-overlay
              :show="cargando_form"
              spinner-variant="primary"
              variant="semi-dark"
            >

              <b-row>
                <!-- DIA -->
                <b-col cols="12">
                  <b-form-group
                    label="Día *"
                    label-for="event-dia"
                  >
                    <v-select
                      v-model="horario.dia"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="optionsDias"
                      :reduce="option => option.id"
                      placeholder="Selecciona el día"
                      :class="v$.horario.dia.$error === true
                        ? 'border-danger rounded'
                        : ''"
                      :clearable="false"
                    />
                    <!-- Mensajes Error Validación -->
                    <div
                      v-if="v$.horario.dia.$error"
                      id="diaInfo"
                      class="text-danger text-right"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.horario.dia.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>

                  </b-form-group>
                </b-col>

                <!-- HORA INICIO -->
                <b-col cols="6">
                  <b-form-group
                    label="Hora Inicio *"
                    label-for="hora-inicio"
                  >

                    <b-input-group>
                      <b-input-group-prepend
                        is-text
                        :class="v$.horario.hora_inicio.$error === true
                          ? 'border-danger rounded-left'
                          : ''"
                      >
                        <feather-icon icon="ClockIcon" />
                      </b-input-group-prepend>
                      <cleave
                        id="hora_inicio"
                        v-model='horario.hora_inicio'
                        :class="v$.horario.hora_inicio.$error === true
                          ? 'form-control border-danger rounded-right'
                          : 'form-control'"
                        :raw="false"
                        :options="time"
                        placeholder="hh:mm"
                        :onValueChanged="formatHoraInicio(horario.hora_inicio)"
                      />

                    </b-input-group>
                    <!-- Mensajes Error Validación -->
                    <div
                      v-if="v$.horario.hora_inicio.$error"
                      id="hora_inicioInfo"
                      class="text-danger text-right"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.horario.hora_inicio.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- HORA TERMINO -->
                <b-col cols="6">
                  <b-form-group
                    label="Hora Termino"
                    label-for="hora-termino"
                  >
                    <b-input-group>
                      <b-input-group-prepend
                        is-text
                        :class="v$.horario.hora_termino.$error === true
                          ? 'border-danger rounded-left'
                          : ''"
                      >
                        <feather-icon icon="ClockIcon" />
                      </b-input-group-prepend>
                      <cleave
                        id="hora_termino"
                        v-model='horario.hora_termino'
                        :class="v$.horario.hora_termino.$error === true
                        ? 'form-control border-danger rounded-right'
                        : 'form-control'"
                        :raw="false"
                        :options="time"
                        placeholder="hh:mm"
                        :disabled="true"
                      />
                        <!-- :onValueChanged="formatHoraTermino(horario.hora_termino)" -->

                    </b-input-group>
                    <!-- Mensajes Error Validación -->
                    <div
                      v-if="v$.horario.hora_termino.$error"
                      id="hora_terminoInfo"
                      class="text-danger text-right"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.horario.hora_termino.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- ASIGNATURA -->
                <b-col cols="12">
                  <b-form-group
                    label="Asignatura *"
                    label-for="event-asignatura"
                  >
                    <b-alert
                      v-if="optionsAsignaturas.length === 0"
                      variant="primary"
                      show
                      class="text-center mt-25 mr-25 ml-25 pb-1 pt-1"
                    >
                      El curso no tiene asignaturas creadas.
                    </b-alert>
                    <v-select
                      v-model="horario.asignatura"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="optionsAsignaturas"
                      :reduce="option => option.id_asignatura"
                      :placeholder="optionsAsignaturas.length === 0
                        ? 'No hay asignaturas disponibles'
                        : 'Selecciona la asignatura'"
                      :selectable="option => option.disabled === true ? false : true"
                      :disabled="optionsAsignaturas.length === 0 || typeof event.id !== 'undefined'"
                      :class="v$.horario.asignatura.$error === true
                        ? 'border-danger rounded'
                        : ''"
                      :clearable="false"
                    />
                    <!-- Mensajes Error Validación -->
                    <div
                      v-if="v$.horario.asignatura.$error"
                      id="asignaturaInfo"
                      class="text-danger text-right"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.horario.asignatura.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>

                  </b-form-group>
                </b-col>
              </b-row>

              <!-- All Day -->
              <!-- <b-form-group>
                <b-form-checkbox
                  v-model="eventLocal.allDay"
                  name="check-button"
                  switch
                  inline
                >
                  Todos los días
                </b-form-checkbox>
              </b-form-group> -->

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="v$.horario.$errors.length > 0"
                >
                  {{ event.id ? 'Guardar hora' : 'Guardar hora ' }}
                </b-button>
                <b-button
                  v-if="event.id"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  title="Eliminar hora"
                  class="btn-icon"
                  @click="$emit('remove-event', event); hide();"
                >

                  Eliminar hora

                    <!-- class="cursor-pointer" -->
                </b-button>
                <!-- <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Cancelar
                </b-button> -->
              </div>
            </b-overlay>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BAvatar, BFormTextarea,
  BButton, BFormInvalidFeedback, BFormTimepicker, BInputGroup, BInputGroupAppend,
  BAlert, BOverlay, BInputGroupPrepend, BCol, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

import { mapActions, mapGetters } from 'vuex'

// CLEAVE
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, helpers, minLength } from '@vuelidate/validators'

// FORMATOS
import { formatos } from '@core/mixins/ui/formatos'

import { ref, toRefs } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'
import useCalendar from '../useCalendar'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    BFormInvalidFeedback,
    BFormTimepicker,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    BOverlay,
    BInputGroupPrepend,
  },
  directives: {
    Ripple,
  },
  mixins: [formatos],
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  computed: {
    ...mapGetters({
      getHorario: 'horarios/getHorario',
      getAsignaturasCurso: 'asignaturas/getAsignaturasCurso',
      user: 'auth/user',
    }),
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
    id_curso_selected: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // datatimer
      id_persona_rol: null,
      time: {
        time: true,
        timePattern: ['h', 'm'],
      },

      optionsAsignaturas: [],
      cargando_form: false,
      id_asignatura: null,
      dia: null,
      horario: {
        id: null,
        asignatura: null,
        dia: null,
        hora_inicio: '08:00',
        hora_termino: '08:20',
      },

      optionsDias: [
        {
          id: 1,
          name: 'Lunes',
        },
        {
          id: 2,
          name: 'Martes',
        },
        {
          id: 3,
          name: 'Miércoles',
        },
        {
          id: 4,
          name: 'Jueves',
        },
        {
          id: 5,
          name: 'Viernes',
        }
      ],
    }
  },
  watch: {
    event (val) {
      if (val.start !== "" && val.end !== "") { // DESDE HORA DENTRO DEL HORARIO
        this.cargando_form = true
        this.$emit('cargarAsignaturasCurso', this.id_curso_selected)
        this.setHorario(val)
      } else if (val.start !== "" && val.end === "") {   // DESDE HORARIO VACÍO
        this.cargando_form = true
        this.$emit('cargarAsignaturasCurso', this.id_curso_selected)
        this.setHorarioFast(val)
      } else {                                  // DESDE BTN
        this.$emit('cargarAsignaturasCurso', this.id_curso_selected)
        this.cargando_form = true
        this.resetForm()
        this.horario = {
          id: null,
          asignatura: null,
          dia: null,
          hora_inicio: '08:00',
          hora_termino: '08:45',
        }

        this.cargando_form = false
      }
    },
    isEventHandlerSidebarActive (val) {
      if (!val) {
        // Creado para que no se vea que se está reseteando al momento de cerrar
        // el side form
        setTimeout(() => this.resetFormHorario(), 200);
      }
    },
    getAsignaturasCurso(val) {
      this.cargaAsignaturasCurso()
      // this.cargando_form = false
    },
  },
  validations() {
    return {
      horario: {
        asignatura: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        dia: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        hora_inicio: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          minLength: helpers.withMessage('No es una hora válida.', minLength(5)),
        },
        hora_termino: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          minLength: helpers.withMessage('No es una hora válida.', minLength(5)),
        },
      }
    }
  },
  mounted() {
    this.cargarPersonaRol()
  },
  methods: {
    ...mapActions({
      attempt: 'auth/attempt',
      fetchHorario: 'horarios/fetchHorario',
    }),
    cargaAsignaturasCurso() {
      this.optionsAsignaturas = []
      this.getAsignaturasCurso.forEach(asignatura => {
        const horasDisponibles = asignatura.horas - asignatura.horas_asignadas
        const disabled = horasDisponibles === 0
        const hr = horasDisponibles === 1 ? 'hr.' : 'hrs.'
        const dis = horasDisponibles === 1 ? 'libre' : 'libres'
        const nombre = asignatura.nombre+' ('+horasDisponibles+' '+hr+' '+dis+')'
        this.optionsAsignaturas.push({
          id_asignatura: asignatura.id,
          title: nombre,
          nombre: asignatura.nombre,
          horas: asignatura.horas,
          disabled,
        })
      })
    },
    cargarPersonaRol() {
      if (!this.user) {
        this.attempt().then(() => {
          this.id_persona_rol = this.user.id_persona_rol
        })
      } else {
        this.id_persona_rol = this.user.id_persona_rol
      }
    },
    resetFormHorario() {
      this.horario = {}
    },
    setAsignaturas(asignaturas) {
      this.optionsAsignaturas = asignaturas
      this.horario = []
    },
    setHorario(event) { // al editar
      this.fetchHorario(event.id).then(() => {
        const {
          id_asignatura,
          numero_dia,
          hora_inicio,
          hora_termino,
        } = this.getHorario

        this.horario = {}

        this.horario = {
          id: event.id,
          asignatura:  id_asignatura,
          dia:  numero_dia,
          hora_inicio:  hora_inicio,
          hora_termino: hora_termino,
        }
        this.cargando_form = false
      })
    },
    setHorarioFast(event) { // al editar
      const fecha_inicio = new Date(event.start)
      const dia = fecha_inicio.getDay()
      let hora = fecha_inicio.getHours()
      let minutos = fecha_inicio.getMinutes()


      if (hora < 10) {
        hora = '0'+hora
      }
      if (minutos === 0) {
        minutos = '00'
      }
      const hora_inicio = hora+':'+minutos

      this.horario = {}

      this.horario = {
        id: null,
        asignatura:  null,
        dia:  dia,
        hora_inicio:  hora_inicio,
        hora_termino:  this.setHoraTermino(hora_inicio),
      }
      this.cargando_form = false
    },
    setHoraTermino(hora_inicio) {
      let hr_inicio = hora_inicio
      const asignatura = this.optionsAsignaturas.find(oa => oa.id_asignatura === this.horario.asignatura)
      const fecha_inicio = new Date('Julio 01, 1999 '+hr_inicio)

      let fecha_termino
      if (typeof asignatura !== 'undefined') {
        if ( asignatura.horas === '0.5' || asignatura.horas === '1.5') {
          fecha_termino = new Date(fecha_inicio.setSeconds(1350))
        } else {
          fecha_termino = new Date(fecha_inicio.setSeconds(2700))
        }
      } else {
        fecha_termino = new Date(fecha_inicio.setSeconds(2700))
      }

      let hora  = fecha_termino.getHours()
      let minutos  = fecha_termino.getMinutes()
      if (hora < 10) {
        hora = '0'+hora
      }
      if (minutos < 10) {
        minutos = '0'+minutos
      }
      const hora_termino = hora+':'+minutos
      return hora_termino
    },
    formatHoraInicio(date) {
      // EJEMPLO: 13:30
      let hora_inicio
      if (typeof date !== 'undefined') {
        const hora = date.split(':')[0]
        const minutos = date.split(':')[1]
        hora_inicio = `${hora}:${minutos}`
        if (minutos === 'undefined') {
          hora_inicio = hora
        }
        if (hora_inicio === ':undefined') {
          hora_inicio = '08:00'
        }
        this.horario.hora_termino = this.setHoraTermino(hora_inicio)
      }
      return hora_inicio
    },
    formatHoraTermino(date) {
      // Este horario no puede ser menor al de la hora de inicio
      // EJEMPLO: 13:30
      const fecha_termino = new Date('Julio 01, 1999 '+date)
      const hora = date.split(':')[0]
      let minutos = date.split(':')[1]
      let hora_termino = `${hora}:${minutos}`
      if (minutos === 'undefined') {
        hora_termino = hora
      }

      return hora_termino
    },
    submitOption() {
      this.v$.horario.$touch()
      if (!this.v$.horario.$invalid) {
        const {
          id,
          dia,
          hora_inicio,
          hora_termino,
          asignatura,
        } = this.horario
        const fechaSeleccionada = this.fechaSeleccionada(dia)
        const start = this.formatoFechaCalendar(fechaSeleccionada, hora_inicio)
        const end = this.formatoFechaCalendar(fechaSeleccionada, hora_termino)
        const nombre_asignatura = this.optionsAsignaturas.find(a => a.id_asignatura === asignatura)
        const nombre_dia = this.optionsDias.find(d => d.id === dia)

        this.eventLocal = {
          id, // idHorario
          id_asignatura: asignatura,
          id_persona_rol: this.id_persona_rol,
          nombre_dia: nombre_dia.name,
          dia: dia,
          hora_inicio: hora_inicio,
          hora_termino: hora_termino,
          title: nombre_asignatura.nombre,
          start,
          end,
          url: '',
          allDay: false,
          extendedProps: {
            calendar: 'Mis bloques',
            description: '',
            guests: [],
            location: '',
          },
        }
        this.v$.$reset();
        return true
      } else {
        return false
      }
    },
    fechaSeleccionada(dia) {
      const fecha = new Date()

      // Obtiene el id del día actual
      // Lun => 1, Mar => 2, Mie => 3, Jue => 4, Vie => 5
      const idDia = fecha.getDay()

      // resta el dia.id seleccionado con el idDia actual
      // para entender la diferencia de días que hay con el día seleccionado
      // y si es un día que está antes o después del día actual.
      const diferencia = dia.id - idDia
      if (Math.sign(diferencia) === 1) {                  // Después
        fecha.setDate(fecha.getDate() + diferencia - 1);
      } else if (Math.sign(diferencia) === -1) {          // Antes
        fecha.setDate(fecha.getDate() + diferencia - 1);
      } else if (Math.sign(diferencia) === 0) {
        fecha.setDate(fecha.getDate() - 1);
      }

      return fecha
    }
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)


    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // VALIDACIONES
      v$: useVuelidate(),

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
