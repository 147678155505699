import { setResponse } from '@/store/asistencias/mutations'
import axios from 'axios'
// import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'success',
        label: 'Mis bloques',
      },
      {
        color: 'danger',
        label: 'Otros bloques',
      },
    ],
    selectedCalendars: ['Mis bloques', 'Otros bloques'],
    id_curso: null,
    id_tipo_ensenanza: null,
    id_persona_rol: null,
    response: {
      status: null,
      message: null,
    },
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    setResponse(state, payload) {
      state.response.status = payload.status
      state.response.message = payload.message
      state.id_persona = payload.id_persona
    }
  },
  actions: {
    async fetchEvents({ctx}, datos) {
      const miHorario = await axios({
        method: 'GET',
        url: `/horariousuariocurso/${datos.id_curso}`,
      })

      const otrosHorarios = await axios({
        method: 'GET',
        url: `/horariousuariocursodistinct/${datos.id_curso}`,
      })

      const data = {
        mi_horario: miHorario.data,
        otros_horarios: otrosHorarios.data,
      }
      return data
    },
    async addEvent({commit}, {event}) {
      const {data} = await axios({
        method: 'POST',
        url: 'horario',
        data: {
          numero_dia: event.dia,
          hora_inicio: event.hora_inicio,
          hora_termino: event.hora_termino,
          id_asignatura: event.id_asignatura,
          id_persona_rol: event.id_persona_rol,
        },
      })
      commit('setResponse', data)
      return data
    },
    async updateEvent({commit}, {event}) {
      const {data} = await axios({
        method: 'PUT',
        url: `horario/${event.id}`,
        data: {
          numero_dia: event.dia,
          hora_inicio: event.hora_inicio,
          hora_termino: event.hora_termino,
          id_asignatura: event.id_asignatura,
          id_persona_rol: event.id_persona_rol,
        },
      })
      commit('setResponse', data)
      return data
    },
    async removeEvent({commit}, { id }) {
      const idHorario = parseInt(id)
      const { data } = await axios({
        method: 'DELETE',
        url: `/horario/${idHorario}`,
      })

      commit('setResponse', data)
      return data
    },
  },
}
