<template>
  <form-wizard
    color="#26a5ea"
    :title="null"
    :subtitle="null"
    class="mb-3"
    shape="square"
    step-size="xs"
    next-button-text="Siguiente"
    back-button-text="Anterior"
    finish-button-text="Finalizar"
    @on-complete="formSubmitted"
  >

    <!-- CURSOS tab -->
    <tab-content
      title="Cursos"
      :before-change="siguienteCursos"
      class="pb-3"
    >
      <b-row>
        <!-- TITULO Y SUBTITULO -->
        <b-col cols="12" sm="12" md="3">
        </b-col>
        <!-- CURSOS v-select -->
        <b-col cols="12" sm="12" md="6" class="mt-3 mb-25">
            <!-- label="Selecciona el o los cursos donde cargarás tus horas pedagógicas." -->
          <p class="text-left text-dark mb-25 h4 font-weight-normal">
            Selecciona tus cursos
          </p>
          <b-form-group
            label-for="cursos"
          >
            <b-alert
              v-if="alert_cursos"
              class="pl-50 pt-25 pb-25 pr-50 mt-50 mb-25"
              variant="primary"
              show
            >
              El establecimiento "{{ this.user.nombre_establecimiento }}", no tienen cursos ni asignaturas creados en este periodo.
            </b-alert>
            <b-overlay
              v-else
              :show="cargando_cursos"
              spinner-variant="primary"
              variant="semi-dark"
            >
              <v-select
                v-model="id_cursos"
                :placeholder="optionsCursos.length === 0
                  ? 'No existen cursos disponibles...'
                  : ''"
                multiple
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :reduce="option => option.id_cursos"
                :options="optionsCursos"
                :class="v$.id_cursos.$error === true
                  ? 'border-danger rounded select-size-lg mt-25'
                  : 'select-size-lg mt-25'"
                :disabled="optionsCursos.length === 0"
                :closeOnSelect="false"
              />

              <div
                v-if="v$.id_cursos.$error"
                id="asistentesInfo"
                class="text-danger text-right"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.id_cursos.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </b-overlay>
          </b-form-group>
        </b-col>
      </b-row>
    </tab-content>

    <!-- HORARIOS tab -->
    <tab-content
      title="Horarios"
      class="mb-3"
    >
      <b-row>
        <!-- ESPACIO -->
        <b-col cols="12" sm="12" md="3">
        </b-col>
        <!-- CURSOS v-select -->
        <b-col cols="12" sm="12" md="6" class="mb-1">
          <p class="text-left text-dark mb-25 h4 font-weight-normal">
            Mis cursos
          </p>
          <b-form-group
            label-for="cursos"
          >
            <b-overlay
              :show="cargando_cursos_horario"
              spinner-variant="primary"
              variant="semi-dark"
            >

              <v-select
                v-model="id_curso_selected"
                :placeholder="optionsCursosHorario.length === 0
                  ? 'No existen cursos disponibles...'
                  : 'Selecciona un curso'"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :reduce="option => option.id_cursos"
                :options="optionsCursosHorario"
                :disabled="optionsCursosHorario.length === 0"
                :clearable="false"
              />
            </b-overlay>
          </b-form-group>
          <b-alert
            show
            variant="primary"
            class="pt-25 pb-25 pl-50 pr-25 mt-0 mb-0 text-center"
          >
            <div class="alert-body text-center mt-25">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              Para ingresar un nuevo bloque, haz click en la hora de un día.
            </div>
          </b-alert>
        </b-col>

            <!-- :class="v$.id_cursos.$error === true
              ? 'border-danger rounded select-size-lg mt-25'
              : 'select-size-lg mt-25'" -->
        <!-- ASIGNATURAS table -->
        <b-col cols="12">
          <horarios-form
            v-if="show_horario_form && id_curso_selected !== null"
            :id_curso_selected.sync="id_curso_selected"
            :id_tipo_ensenanza.sync="curso_selected.id_tipo_ensenanza"
          />
        </b-col>
      </b-row>
    </tab-content>

  </form-wizard>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCard,
  BAlert,
  BTable,
  BOverlay,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import store from '@/store/index'
import { mapGetters, mapActions, mapMutations } from 'vuex'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, email, helpers } from '@vuelidate/validators'

// COMPONENTES HIJOS
import HorariosForm from '../Mantenedores/Horarios/components/HorariosForm.vue'


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BCard,
    BAlert,
    BTable,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    // COMPONENTES HIJOS
    HorariosForm,
  },
  data() {
    return {
      id_persona_rol: null,
      id_establecimiento: null,
      // CURSOS
      alert_cursos: false,
      cargando_cursos: true,
      cargando_cursos_horario: true,
      id_cursos: [],
      optionsCursos: [],
      // HORARIOS
      id_curso_selected: null,
      show_horario_form: false,
      optionsCursosHorario: [],
      cursosHorarioForm: [],
      idCursosHorarioForm: [],

      // CONFIGURACION INICIAL
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
    }
  },
  validations() {
    return {
        id_cursos: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
    }
  },
  computed: {
    ...mapGetters({
      getCursos: 'cursos/getCursos',
      getCursosPersonaRol: 'cursos/getCursosPersonaRol',
      getAsignaturasCurso: 'asignaturas/getAsignaturasCurso',
      getHorarios: 'horarios/getHorarios',
      user: 'auth/user',
    }),
    curso_selected () {
      return this.optionsCursosHorario.find(c => c.id_cursos === this.id_curso_selected)
    },
  },
  watch: {
    getCursosPersonaRol(val) {
      this.cargando_cursos = false
    },
    user (val) {
      this.cargarData(val)
    }
  },
  mounted() {
    this.cargarUsuario(this.user)

  },
  methods: {
    ...mapActions({
      fetchCursosEstablecimiento: 'cursos/fetchCursosEstablecimiento',
      fetchCursosPersonaRol: 'cursos/fetchCursosPersonaRol',
      addCursoPersona: 'cursos/addCursoPersona',
      removeCursoPersona: 'cursos/removeCursoPersona',
      fetchAsignaturasCurso: 'asignaturas/fetchAsignaturasCurso',
      attempt: 'auth/attempt',
      fetchHorarioUsuarioCurso: 'horarios/fetchHorarioUsuarioCurso',
      desactivaConfiguracionInicial: 'configuracionInicial/desactivaConfiguracionInicial',
    }),
    cargarUsuario() {
      if (!this.user) {
        this.attempt().then(() => {})
      } else {
        this.cargarData(this.user)
      }
    },
    cargarData(val) {
      this.id_persona_rol = null
      this.id_persona_rol = val.id_persona_rol
      this.id_establecimiento = null
      this.id_establecimiento = val.id_establecimiento
      this.cargando_cursos = true
      this.cargarCursosOptions(this.id_establecimiento)
      this.cargarCursosUsuario(this.id_persona_rol)
    },
    cargarCursosOptions(id_establecimiento) {
      this.fetchCursosEstablecimiento(id_establecimiento).then(() => {
        if (this.getCursos.length !== 0) {
          this.optionsCursos = []
          this.getCursos.forEach(curso => {
            const nombre = curso.nombre+' '+curso.letra
            this.optionsCursos.push({
              id_cursos: curso.id,
              title: nombre,
            })
          })

        } else {
          this.alert_cursos = true
        }
      })
    },
    cargarCursosUsuario(id_persona_rol) {
      this.cargando_cursos = true
      this.fetchCursosPersonaRol(id_persona_rol).then(() => {
        if (this.id_cursos.length === 0) {
          this.id_cursos = []
          this.getCursosPersonaRol.forEach(curso => {
            this.id_cursos.push(curso.id)
          })
        }
      })

    },
    cargarCursosHorario(id_persona_rol) {
      this.cargando_cursos = true
      this.fetchCursosPersonaRol(id_persona_rol).then(() => {
        this.id_curso_selected = null
        this.optionsCursosHorario = []
        this.id_curso_selected = this.getCursosPersonaRol[0]['id']
        this.getCursosPersonaRol.forEach(curso => {
          const nombre = curso.nombre+' '+curso.letra
          this.optionsCursosHorario.push({
            id_cursos: curso.id,
            title: nombre,
            id_tipo_ensenanza: curso.id_tipo_ensenanza,
          })
        })
        this.cargando_cursos_horario = false
      })
    },

    // SieguienteCurso
    siguienteCursos() {
      return new Promise((resolve, reject) => {
        this.v$.id_cursos.$touch()
        let cursos_id = []
        this.getCursosPersonaRol.forEach(curso => {
          cursos_id.push(curso.id)
        })

        // Compara los cursos
        const id_cursos_eliminar = cursos_id.filter(x => this.id_cursos.indexOf(x) === -1);
        this.cargando_cursos_horario = true
        this.id_curso_selected = null
        this.optionsCursosHorario = []

        if (!this.v$.id_cursos.$invalid) {
          this.show_horario_form = false
          if (id_cursos_eliminar.length > 0) {
            this.$swal({
              title: 'Eliminar curso!',
              html: `Acabas de quitar uno o más cursos, si das en "Siguiente"
              el curso se eliminará.
              <br>¿Estás seguro(a) que quieres seguir?`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Sí, siguiente',
              cancelButtonText: 'Cancelar',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              this.spinner = true
              if (result.value) {
                id_cursos_eliminar.forEach(curso => {
                  this.eliminarCursos(curso)
                })

                if (this.id_cursos.length > 0) {
                  this.id_cursos.forEach(curso => {
                    this.agregarCursoPersona(curso)
                  })
                }
                this.setHorarios()
                resolve(true)
              } else {
                reject()
              }
            })

          } else {
            if (this.id_cursos.length > 0) {
              this.id_cursos.forEach(curso => {
                this.agregarCursoPersona(curso)
              })
              this.setHorarios()

            }
            resolve(true)
          }
        } else {
          console.log('ERRRORRRR :')
          // MENSAJE DE VALIDACIÓN
          reject()
        }
      })
    },
    agregarCursoPersona(curso) {
      const data = {
        id_persona_rol: this.id_persona_rol,
        id_cursos: curso,
        pie: 0,
      }
      this.addCursoPersona(data).then(() => {
        const ultimoIngresado = this.optionsCursos.find(c => c.id_cursos === curso)
        const stateCursos = store.state.cursos
        if (stateCursos.status === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'success',
              title: 'Curso asignado 👍',
              text: `El curso "${ultimoIngresado.title}" fue asignado con éxito!`,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 5000,
          })
        } else {
          // console.log('Error!', stateCursos.message)
        }
      })
    },
    eliminarCursos(curso) {
      const cursoPersonaRol = this.getCursosPersonaRol.find(c => c.id === curso)
      const nombre_curso = cursoPersonaRol.nombre+' '+cursoPersonaRol.letra
      this.removeCursoPersona(cursoPersonaRol.id_cursos_rol).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'success',
            title: 'Curso eliminado 👍',
            text: `El curso "${nombre_curso}" fue eliminado con éxito!`,
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
          timeout: 5000,
        })
        this.spinner = false
      })
    },
    // FIN SieguienteCurso

    // HORARIOS
    setHorarios() {
      this.cargarCursosHorario(this.id_persona_rol)
      this.show_horario_form = true
    },

    setMensaje(cursosSinHorario) {
      let mensaje
      if (cursosSinHorario.length === 1) {
        const nombre_curso = this.optionsCursos.find(oc => oc.id_cursos === cursosSinHorario[0])
        mensaje = `
          El curso "${nombre_curso.title}", no tiene horas agregadas.
          <br>Estás seguro(a) de finalizar la carga horaria?`
      } else {

        let nombre_cursos = ''
        cursosSinHorario.forEach((curso, key) => {

          const nombre_curso = this.optionsCursos.find(oc => oc.id_cursos === curso)
          if (cursosSinHorario.length === key+1) {
            nombre_cursos += nombre_curso.title
          } else {
            nombre_cursos += nombre_curso.title +' - '
          }
        })
        mensaje = `
          Los cursos "${nombre_cursos}", no tienen horas agregadas.
          <br>Estás seguro(a) de finalizar la carga horaria?`
      }


      return mensaje
    },
    formSubmitted() {
      // Valida si todos los cursos cargados tienen horas ingresadas.
      let cursosSinHorario = []
      this.id_cursos.forEach((curso, key) => {
        this.fetchHorarioUsuarioCurso(curso).then(() => {
          if (this.getHorarios.length === 0) {
            cursosSinHorario.push(curso)
          }

          if (key+1 === this.id_cursos.length) {
            if (cursosSinHorario.length > 0) {

              const mensaje = this.setMensaje(cursosSinHorario)
              // const mensaje = cursosSinHorario.length === 1
              //   ? 'El curso, no tiene horas ingresadas.'
              //   : 'Los cursos, no tienen horas ingresadas'
              this.$swal({
                title: 'Faltan cursos!',
                html: mensaje,
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Sí, finalizar!',
                cancelButtonText: 'Cancelar',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then(result => {
                this.spinner = true
                if (result.value) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: 'Finalizado con éxito!',
                      icon: 'CheckIcon',
                      text: 'La carga horaria ha sido guardada correctamente!',
                      variant: 'success',
                    },
                  },
                  {
                    position: 'bottom-right',
                    timeout: 5000,
                  })


                  this.$router.replace({
                    name: 'mis-horarios',
                  })
                } else {
                  this.spinner = false
                }
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: 'Finalizado con éxito!',
                  icon: 'CheckIcon',
                  text: 'La carga horaria ha sido guardada correctamente!',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
                timeout: 5000,
              })


              this.$router.replace({
                name: 'mis-horarios',
              })
              // this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'vertical')
              // this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
              // this.$store.commit('appConfig/UPDATE_SKIN', 'semi-dark')
            }
          }
        })
      })
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  created() {

  },
  destroyed() {
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>