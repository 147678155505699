<template>
  <!-- <b-modal
    :id="idModal"
    :title="title"
    centered
    size="xl"
    :hide-footer="true"
  > -->
    <div class="app-calendar overflow-hidden border">
      <b-overlay
        :show="carga_horario"
        spinner-variant="primary"
        variant="semi-dark"
      >
        <div class="row no-gutters">
          <!-- Sidebar -->
          <div
            class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
            :class="{'show': isCalendarOverlaySidebarActive}"
          >
            <calendar-sidebar
              :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
            />
          </div>

          <!-- Calendar -->
          <div class="col position-relative">
            <div class="card shadow-none border-0 mb-0 rounded-0">
              <div class="card-body pb-0">
                <full-calendar
                  ref="refCalendar"
                  :options="calendarOptions"
                  class="full-calendar"
                  initialDate="2022-10-10"
                />
              </div>
            </div>
          </div>


          <!-- Sidebar Overlay -->
          <div
            class="body-content-overlay"
            :class="{'show': isCalendarOverlaySidebarActive}"
            @click="isCalendarOverlaySidebarActive = false"
          />
          <calendar-event-handler
            v-model="isEventHandlerSidebarActive"
            :event.sync="event"
            :clear-event-data="clearEventData"
            :id_curso_selected.sync="id_curso_selected"
            @remove-event="remove"
            @add-event="addEvent"
            @update-event="updateEvent"
            @cargarAsignaturasCurso="cargarAsignaturasCurso(id_curso_selected)"
          />
            <!-- :optionsAsignaturas.sync="optionsAsignaturas" -->
        </div>
      </b-overlay>
    </div>

   <!-- </b-modal> -->
</template>

<script>
// ETIQUETAS
import { BModal, VBModal, BOverlay } from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted, watch } from '@vue/composition-api';
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import calendarStoreModule from './calendarStoreModule'
import useCalendar from './useCalendar'

import { mapGetters, mapActions, mapMutations } from 'vuex'

// FORMATOS
import { formatos } from '@core/mixins/ui/formatos'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    VBModal,
    BModal,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [formatos],
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    fetchEvents()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
  data() {
    return {
      carga_horario: true,
      optionsAsignaturas: [],
    }
  },
  computed: {
    ...mapGetters({
      getAsignaturasCurso: 'asignaturas/getAsignaturasCurso',
    }),
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    id_curso_selected: {
      type: Number,
      required: true,
    },
    id_tipo_ensenanza: {
      type: Number,
      required: false,
    },
  },
  watch: {
    id_curso_selected(val) {
      this.carga_horario = true
      this.setCalendarApi(val, this.id_tipo_ensenanza)
      this.cargarAsignaturasCurso(val)
    },
    id_tipo_ensenanza(id_tipo_ensenanza) {
      this.setCalendarApi(this.id_curso_selected, id_tipo_ensenanza)
    },
  },
  mounted() {
    if (typeof this.id_tipo_ensenanza !== 'undefined') {
      this.setCalendarApi(this.id_curso_selected, this.id_tipo_ensenanza)
    }
    this.cargarAsignaturasCurso(this.id_curso_selected)
  },
  methods: {
    ...mapActions({
      fetchAsignaturasCurso: 'asignaturas/fetchAsignaturasCurso',
    }),
    setCalendarApi(id_curso, id_tipo_ensenanza) {
      store.state.calendar.id_curso = id_curso
      store.state.calendar.id_tipo_ensenanza = id_tipo_ensenanza
    },
    cargarAsignaturasCurso(curso) {
      this.fetchAsignaturasCurso(curso).then(() => {
        this.carga_horario = false
      })
    },
    remove(event) {
      const html = this.formatHTMLSweetEliminar('la hora', '')
      this.$swal({
        title: 'Eliminar hora!',
        html,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, elimínalo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          this.removeEvent(event.id)
          this.cargarAsignaturasCurso(this.id_curso_selected)
        } else {
          this.spinner = false
        }
      })

    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
